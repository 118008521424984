@import 'src/styles/variables/variables';

@include useTheme();

.Container {
  text-align: center;
  background-color: $core4;

  p {
    margin-bottom: 0;
  }

  &.isNewHomePage {
    background-color: $tint4;
    padding: 66px 17px;

    @include min-width(small-tablet) {
      padding: 59px 49px 0;
      overflow: hidden;
    }

    @include min-width(desktop) {
      padding-top: 86px;
    }
  }
}

.PricingCards {
  padding: 50px 20px;

  @include min-width(large-tablet) {
    padding: 67px 0 !important;
  }
}

.isNewHomePage {
  .PricingCards {
    padding: 0;
  }
}

.CTA {
  @include max-width(large-tablet) {
    span {
      display: none;
    }
  }
}
