@import 'src/styles/variables/variables';

.Root {
  @include min-width(desktop) {
    max-width: 980px;
    margin: 0 auto;
  }

  @include min-width(medium-desktop) {
    max-width: 1336px;
  }
}

.CardsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  justify-content: center;

  @include min-width(small-tablet) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: stretch;
    padding-bottom: 79px;

    > div:not(:last-child) {
      display: flex;
      background: #fff;
      z-index: 1;
    }
  }

  @include min-width(desktop) {
    padding-bottom: 104px;
  }

  @include min-width(medium-desktop) {
    padding-bottom: 80px;
  }
}

.sectionTitle {
  @include tertiaryFont(500);
  font-size: 14px;
  font-style: normal;
  line-height: 18px; /* 128.571% */
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;

  @include min-width(small-tablet) {
    margin-bottom: 40px;
  }

  @include min-width(desktop) {
    margin-bottom: 77px;
  }

  @include min-width(medium-desktop) {
    margin-bottom: 48px;
  }
}

.sectionDesc {
  @include secondaryFont(400);
  font-size: 28px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.4px;
  display: block;
  max-width: 333px;
  margin: 0 auto 48px;

  @include min-width(small-tablet) {
    max-width: inherit;
    text-align: left;
    margin-bottom: 38px;
  }

  @include min-width(desktop) {
    max-width: 858px;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.4px;
    text-align: left;
    margin: 0 0 53px;
  }

  @include min-width(medium-desktop) {
    margin-bottom: 52px;
  }
}

.PackageImage {
  display: none;
  width: calc(100% + 34px);
  justify-content: center;
  align-items: center;
  margin: -42px -17px 0;

  img {
    object-fit: cover;
    max-width: 100%;
  }

  @include min-width(large-tablet) {
    display: block;
    position: relative;
    margin: 0;
    z-index: 0;

    img {
      position: absolute;
      top: 50%;
      right: 0;
      max-width: none;
      width: calc(100% + 202px);
      transform: translate(81px, -50%);
      margin-top: -1px;
    }
  }

  @include min-width(desktop) {
    img {
      position: absolute;
      right: 0;
      max-width: none;
      margin-top: -1px;
      top: 100%;
      width: calc(100% + 335px);
      transform: translate(178px, -92%);
    }
  }

  @include min-width(medium-desktop) {
    width: 866px;

    img {
      width: 866px;
      transform: translate(150px, -84.9%);
    }
  }
}
